header#home {
  background-image: url("../images/header_home.jpg");
  background-size: cover;
  background-position: 0 43%;
}

header#contact {
  background-image: url("../images/header_contact.png");
  background-size: cover;
  background-position: center;
}

header#kennismaken {
  background-image: url("../images/header_kennismaken.png");
  background-size: cover;
  background-position: center;

  @include media-breakpoint-down(lg) {
    background-position: 0 70% !important;
  }
}

header#metaforen {
  background-image: url("../images/header_home.jpg");
  background-size: cover;
  background-position: center;
}

section {
    padding: 100px 0;
}